import React, { FC } from 'react';
import { CouponInfo } from '../../../../types/coupons';
import { ServiceData, SlotService } from '../../../../utils/state/types';
import { isFixedPrice } from '../../../../utils/payment';
import { mapToArray } from '../../../../utils';
import {
  Checkbox,
  SectionNotification,
  SectionNotificationType,
} from 'wix-ui-tpa/cssVars';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useFormActions } from '../../Hooks/useFormActions';
import { SelectedPaymentOption } from '../../../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import { classes, st } from './CouponCheckbox.st.css';
import { CouponCheckboxDatahooks } from './datahooks';
import Text, { TextType } from '../../Text/Text';

export interface CouponCheckboxProps {
  isCart?: boolean;
  couponInfo: CouponInfo;
  serviceData: ServiceData;
}

export const CouponCheckbox: FC<CouponCheckboxProps> = ({
  isCart,
  couponInfo,
  serviceData,
}) => {
  const [checked, setChecked] = React.useState(
    couponInfo.isCouponCheckboxChecked,
  );
  const { onCouponCheckboxCheck } = useFormActions();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  // eslint-disable-next-line
  const handleCheckboxChange = (checked: boolean) => {
    console.log('here');
    setChecked(checked);
    onCouponCheckboxCheck(checked);
  };

  const slotService = mapToArray<SlotService>(serviceData.slotServices)[0];

  const shouldShowCouponCheckbox =
    !isCart &&
    couponInfo.areCouponsAvailable &&
    serviceData.isSingleService &&
    isFixedPrice(slotService.service.payment) &&
    slotService.selectedPaymentType === SelectedPaymentOption.OFFLINE;

  if (!shouldShowCouponCheckbox) {
    return null;
  }

  return (
    <div className={st(classes.root, { isMobile })}>
      <Checkbox
        className={classes.checkbox}
        data-hook={CouponCheckboxDatahooks.Checkbox}
        checked={checked}
        label={t('app.bookings-form.coupon-checkbox.label')}
        // eslint-disable-next-line
      onChange={({ checked }) => handleCheckboxChange(checked)}
      />
      {checked && (
        <SectionNotification
          data-hook={CouponCheckboxDatahooks.SectionMessage}
          className={classes.message}
          type={SectionNotificationType.default}
        >
          <SectionNotification.Text>
            <Text type={TextType.Primary}>
              {t('app.bookings-form.coupon-checkbox.section-message')}
            </Text>
          </SectionNotification.Text>
        </SectionNotification>
      )}
    </div>
  );
};
